import React from "react";
import ClockIcon from "./ClockIcon";

const CalenderTile = ({ data }) => {
  return (
    <a href={data.location} target="_blank">
      <div
        className="relative flex w-full h-auto bg-blue-100 rounded-lg"
        style={{ marginLeft: "5px", height: "160px" }}
      >
        <div
          className="flex-1 bg-blue-900 text-white font-bold flex flex-col items-center justify-center rounded-lg "
          style={{ width: "70%" }}
        >
          <p className="flex flex-col items-center text-xl ">
            <span>{data.date}</span>
            <span> {data.month}</span>
          </p>
        </div>
        <div
          className="flex flex-col justify-center px-2 text-left"
          style={{ width: "70%" }}
        >
          <div className="flex items-center">
            <ClockIcon className="w-4 h-4 mr-1" />
            <span className="text-sm text-black">{data.time}</span>
          </div>
          <h3 className="text-sm font-bold">{data.caption}</h3>
          <div className="event-one__text">
            <p className="text-sm text-black">{data.venue}</p>
          </div>
        </div>
      </div>
    </a>
  );
};

export default CalenderTile;
